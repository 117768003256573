import React from 'react'
import BoxStaticPageList from '../../../src/common/components/BoxStaticPageList/BoxStaticPageList'
import LayoutLanding from '../../../src/common/components/layouts/layoutLanding/LayoutLanding'

interface Props {
  styles: { [k: string]: string }
}

const sectionsPt = [
  {
    title: 'Serviços Mecânicos',
    items: [
      {
        title: 'Travões de carro',
        description: '',
        img: `https://${process.env.RODI_AWS_BUCKET}/carro/travoes-para-carro.jpg`,
        route: 'coche.frenos',
      },
      {
        title: 'Correia de distribuição',
        description: '',
        img: `https://${process.env.RODI_AWS_BUCKET}/carro/correia-distribuicao.jpg`,
        route: 'coche.correa',
      },
      {
        title: 'Amorteçedores de carro',
        description: '',
        img: `https://${process.env.RODI_AWS_BUCKET}/carro/amortecedores-carro.jpg`,
        route: 'coche.amortiguadores',
      },
    ],
  },
  {
    title: 'Serviços de manutenção',
    items: [
      {
        title: 'Mudança dos pneus',
        description: '',
        img: `https://${process.env.RODI_AWS_BUCKET}/carro/mudanca-pneus.jpg`,
        route: 'neumaticos.index',
      },
      {
        title: 'Mudança do óleo',
        description: '',
        img: `https://${process.env.RODI_AWS_BUCKET}/carro/mudanca-oleo-carro.jpg`,
        route: 'revisiones',
      },
      {
        title: 'Mudança dos filtros',
        description: '',
        img: `https://${process.env.RODI_AWS_BUCKET}/carro/filtros-do-carro.jpg`,
        route: 'coche.filtros',
      },
      {
        title: 'Mudança da bateria',
        description: '',
        img: `https://${process.env.RODI_AWS_BUCKET}/carro/baterias-carro.jpg`,
        route: 'coche.baterias',
      },
    ],
  },
  {
    title: 'Serviços de afinação',
    items: [
      {
        title: 'Carga de ar condicionado',
        description: '',
        img: `https://${process.env.RODI_AWS_BUCKET}/carro/carga-ar-condicionado.jpg`,
        route: 'coche.aire_acondicionado',
      },
      {
        title: 'Escovas limpa-para-brisas',
        description: '',
        img: `https://${process.env.RODI_AWS_BUCKET}/carro/escovas-limpa-para-brisas.jpg`,
        route: 'coche.escobillas',
      },
    ],
  },
]

const CochePt = ({ styles }: Props) => (
  <LayoutLanding
    title="Serviços para o seu carro"
    textRecomendation="Os elementos do carro desgastam-se com o uso e a passagem do tempo. Ao verificá-los, verifica-se o seu estado, que é essencial para uma condução segura. Se cumpre com as estipulações dos fabricantes, ganhará paz de espírito e segurança."
    descriptionCTA="Precisa que lhe façam a manutenção do seu carro? Encontrará tudo o que o seu veículo precisa na Rodi Motor. Descubra agora!"
    linkButtonCTA="/marcacao-previa/selecao-oficina/"
    textButtonCTA="SOLICITAR MARCAÇÃO PRÉVIA">
    <React.Fragment>
      <p>
        Na Rodi Motor somos especializados na manutenção do seu carro. Temos
        serviços mecânicos abrangentes que oferecem soluções adaptadas às
        necessidades de cada condutor e de cada carro ao melhor preço. Tratamos
        da mudança do óleo, travões, amortecedores, bateria, filtros de motor...
      </p>
      <p>
        Explicamos-lhe todos os serviços que encontrará nas oficinas da Rodi
        Motor by Covipneus.
      </p>
      {sectionsPt.map((section) => (
        <BoxStaticPageList
          key={section.title}
          title={section.title}
          items={section.items}
        />
      ))}
    </React.Fragment>
  </LayoutLanding>
)

const CocheContents = ({ ...props }: Props) => <CochePt {...props} />

export default CocheContents
